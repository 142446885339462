import { createReducer } from '@solid-primitives/memo'
import { createLocalStorage } from '@solid-primitives/storage'
import { css } from 'goober'
import { hc } from 'hono/client'
import { createEffect } from 'solid-js'
import { useRouteData } from 'solid-start'
import { createServerData$ } from 'solid-start/server'
import { Header, Score, Map, Station, Feature, Attention, Footer } from '~/components/detail'
import { Title, Meta, Article } from '~/components/html-living-standard'
import NotFound from '~/routes/[...404]'
import { createDispatcher } from '~/utils/create-dispatcher'

export function routeData({ params }: RouteDataArgs) {
  const data = createServerData$(async ([id]) => {
    const api = hc<APIRoutes>(import.meta.env.VITE_SCHNAVI_API_ROOT_URL)
    const res = await api.rooms.details.$post({ json: { id } })
    const { data } = await res.json()
    return data as DataModel
  }, { key: () => [params.id] })
  return { data }
}

export default function Page() {
  const { data } = useRouteData<typeof routeData>()
  const data$ = data()
  // favorite
  const dispatcher = createDispatcher()
  const [favoriteStore, setFavoriteStore] = createLocalStorage()
  const [favorite] = createReducer(dispatcher, JSON.parse(favoriteStore.data) as string[] ?? [])
  createEffect(() => {
    const data = favorite()
    setFavoriteStore('data', JSON.stringify(data)) // sync
  })
  if (!data$) return <NotFound />
  // console.log(JSON.parse(JSON.stringify(data$)))
  const { id, web, name, zip, address, station, feature, course, grade, lonLat, tel, review } = data$
  const Style = {
    Block: {
      container: css({
        // footer height
        '& > div:last-child::after': {
          content: 'none'
        },
        '&::-webkit-scrollbar': { display: 'none' },
        height: '100dvh',
        overflowY: 'scroll',
        paddingBottom: '80px'
      })
    }
  }
  const { Block } = Style
  return (
    <>
      <Title>{`${name} - ${import.meta.env.VITE_SERVICE_NAME}`}</Title>
      <Meta content={`${name}のページ - ${import.meta.env.VITE_SERVICE_NAME}`} name='description' use='solid' />
      <Article class={Block.container}>
        <Header {...{ address, course, grade, id, name, zip }} />
        {review.length ? <Score {...{ review }} /> : <></>}
        <Map center={[lonLat.lon, lonLat.lat]} />
        {station.length ? <Station {...{ station }} /> : <></>}
        {feature.length ? <Feature {...{ feature }} /> : <></>}
        {/*{message.title && message.text ? <Message {...{ message }} /> : <></>}*/}
        {/*{review.length ? <Review {...{ review }} /> : <></>}*/}
        {/*{faq.length ? <Faq {...{ faq }} /> : <></>}*/}
        <Attention />
      </Article>
      <Footer {...{ tel, web }} />
    </>
  )
}
