import { css } from 'goober'
import { splitProps } from 'solid-js'
import { Html as SolidStartHtml } from 'solid-start'

type Props = ComponentProps<'head'>

export function Html(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const Style = {
    Block: {
      container: css({
        WebkitFontSmoothing: 'antialiased',
        'a, button, input': {
          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
        },
        // ios disable pull to refresh
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans,ヒラギノ角ゴ ProN W3', Arial, メイリオ, Meiryo, sans-serif",
        overflow: 'hidden'
      })
    }
  }
  const { Block } = Style
  const [component, attributes] = splitProps(props, ['children'])
  return <SolidStartHtml class={Block.container} lang={attributes.lang}>{component.children}</SolidStartHtml>
}
