import { Suspense } from 'solid-js'
import { Assets } from 'solid-js/web'
import { ErrorBoundary, Routes, FileRoutes, Scripts } from 'solid-start'
import { Goober } from '~/components/extension'
import { Html, Head, Meta, Body, Main } from '~/components/html-living-standard'
import 'the-new-css-reset/css/reset.css'

export default function Root() {
  return (
    <Html lang='ja'>
      <Head>
        <Meta charset='utf-8' />
        <Meta content='width=device-width, initial-scale=1' name='viewport' />
        <Assets>
          <Goober />
        </Assets>
      </Head>
      <Body>
        <Main>
          <Suspense>
            <ErrorBoundary>
              <Routes>
                <FileRoutes />
              </Routes>
            </ErrorBoundary>
          </Suspense>
          <Scripts />
        </Main>
      </Body>
    </Html>
  )
}
