import { splitProps } from 'solid-js'

type Props = ComponentProps<'body'>

export function Body(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <body {...attributes}>{component.children}</body>
}
