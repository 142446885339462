import { splitProps } from 'solid-js'
import { Head as SolidStartHead } from 'solid-start'

type Props = ComponentProps<'head'>

export function Head(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <SolidStartHead {...attributes}>{component.children}</SolidStartHead>
}
