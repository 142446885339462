import { splitProps } from 'solid-js'
import { Meta as SolidMeta } from 'solid-start'

type Props = ComponentProps<'meta'> & {
  use?: 'solid'
}

export function Meta(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [, attributes] = splitProps(props, ['children'])
  return props.use === 'solid' ? <SolidMeta {...attributes} /> : <meta {...attributes} />
}
